import "../Styles/aboutme.css";
import Audiodescription from "./Audiodescription";
import Slideshow from "./slideshow";
const Homepage = ({ home, setHome, animationLeave }) => {
  const description =
    "I am currently a third-year student at Wilfrid Laurier University pursuing a double major in Computer Science and Film Studies. But when I'm not doing academics I do.... a lot. This website is a highlight of what I enjoy doing in my free time. Feel free to take a look around! I highly recommend starting in the coding section as it contains my most recent work.";
  return (
    <div className={animationLeave === true ? "entire-page" : ""}>
      <div className="aboutme-container">
        <h1 className="homeheader-text">Hi there! I'm Alec.</h1>
        <div className="images-body"></div>
      </div>
      <div className="slideshow-description-container">
        <div className="slideshow-container">
          <Slideshow></Slideshow>
        </div>
        <div className="homedescription-container">
          <Audiodescription
            description={description}
            link="15lDttJTO3c"
          ></Audiodescription>
        </div>
      </div>
      <div className="aboutme-container">
        <h1 className="homeheader-text">Want to get in touch?</h1>
        <center>
          <div className="contactme-container">
            <div className="reachme-container">
              <h1>Email</h1>
              <a className="contact-link" href="mailto:hallman.alec13@gmailcom">
                hallman.alec13@gmail.com
              </a>
            </div>
            <div className="reachme-container">
              <h1>Phone</h1>
              <p className="contact-link">519-270-9622</p>
            </div>
            <div className="reachme-container">
              <h1>Linkedin</h1>
              <a
                className="contact-link"
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.linkedin.com/in/alec-hallman-324247262/?profileId=ACoAAEB2rQwBpHIVLD6SkPlpbaTsthy4RwGmpfI"
              >
                Alec Hallman
              </a>
            </div>
          </div>
        </center>
      </div>
    </div>
  );
};
export default Homepage;
